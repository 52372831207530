import { Box, BoxProps, Stack, Typography, useTheme } from '@mui/material';

import { Link, useLocation } from '@remix-run/react';

import Button from '~/components/atoms/Button';
import Heading from '~/components/atoms/Heading';

import { imageUrls } from '~/assets';
import { mergeSx } from '~/utils/mui';
import { AllvitPlussBannerDashboard } from './variants/AllvitPlussBannerDashboard';
import { Dispatch, SetStateAction } from 'react';

interface AllvitPlussBannerProps extends BoxProps {
    variant: AllvitPlussBannerVariant;
    cartItemsAvailableInSubscription?: any[];
    header?: string;
    body?: string;
    userIsSubscriber?: boolean;
    setShowSubModal?: Dispatch<SetStateAction<boolean>>;
}

export enum AllvitPlussBannerVariant {
    BookDetails = 'BOOK_DETAILS',
    Profile = 'PROFILE',
    Main = 'MAIN',
    Cart = 'CART',
    Dashboard = 'DASHBOARD',
}

export const AllvitPlussBanner = ({
    header,
    body,
    sx,
    variant,
    cartItemsAvailableInSubscription,
    userIsSubscriber,
    setShowSubModal,
}: AllvitPlussBannerProps): JSX.Element => {
    const theme = useTheme();
    const location = useLocation();

    if (variant === AllvitPlussBannerVariant.Profile) {
        return (
            <Stack
                sx={{
                    mb: { xs: 8, md: 12 },
                    p: 6,
                    flexDirection: 'row',
                    justifyContent: { xs: 'center', sm: 'space-between' },
                    backgroundColor:
                        theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.dark,
                    borderRadius: theme.radius.allLarge,
                    backgroundImage: { xs: 'none', sm: `url(${imageUrls.extractedSmartStudent})` },
                    backgroundPosition: 'bottom right',
                    backgroundSize: { sm: '240px auto', md: '290px auto', lg: '310px auto' },
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Stack sx={{ alignItems: { xs: 'center', sm: 'flex-start' } }}>
                    <Heading
                        variant="h5"
                        sx={{
                            mb: 4,
                            mr: { xs: '0', md: '260px' },
                            color: theme.palette.primary.contrastText,
                            textAlign: { xs: 'center', sm: 'initial' },
                        }}
                    >
                        Spar enda mer!
                    </Heading>
                    <Typography
                        variant="p2"
                        sx={{
                            mb: 6,
                            mr: { xs: '0', sm: '260px' },
                            color: theme.palette.primary.contrastText,
                            textAlign: { xs: 'center', sm: 'initial' },
                        }}
                        data-cy="cy-book-status-offshelf-subscription"
                    >
                        Visste du at du kan abonnere på pensumbøker og få tilgang til omtrent 2000 bøker?
                    </Typography>

                    <Button
                        variant="primary"
                        size="large"
                        overrideColor={theme.palette.primary.contrastText}
                        component={Link}
                        to="/allvit-pluss"
                        sx={{ width: 'fit-content' }}
                    >
                        Les mer om Allvit+
                    </Button>
                </Stack>
            </Stack>
        );
    }

    if (variant === AllvitPlussBannerVariant.Main) {
        return (
            <Box
                sx={mergeSx(
                    {
                        p: { xs: 8, sm: 12 },
                        width: 1,
                        backgroundColor:
                            theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.dark,
                        backgroundImage: { sm: 'none', md: `url(${imageUrls.extractedSmartStudent})` },
                        backgroundSize: {
                            md: '200px auto',
                            lg: '350px auto',
                            xl: '380px auto',
                        },
                        backgroundRepeat: 'no-repeat',
                        borderRadius: theme.radius.allXLarge,
                        backgroundPosition: 'bottom right',
                    },
                    sx,
                )}
            >
                <Heading variant="h2" sx={{ mb: 6, textAlign: { xs: 'center', sm: 'left' } }}>
                    {header || 'Abonnér på dine pensumbøker!'}
                </Heading>
                <Typography
                    variant="p1"
                    sx={{
                        display: 'block',
                        mb: 12,
                        color: theme.palette.primary.contrastText,
                        textAlign: { xs: 'center', sm: 'left' },
                        maxWidth: { lg: '560px', xl: '740px' },
                    }}
                >
                    {body || (
                        <>
                            Med Allvit+ kan du abonnere på pensumbøker og få tilgang til omtrent 2000 bøker!{' '}
                            <Button
                                component={Link}
                                to="/sok/boker?allvitPluss=1&gratis=1"
                                state={{ from: location.pathname }}
                                overrideColor={theme.palette.text.primary}
                                variant="link"
                            >
                                Søk blant bøkene
                            </Button>{' '}
                            som er inkludert i Allvit+ for å sjekke om vi har dine pensumbøker.
                        </>
                    )}
                </Typography>
                <Stack
                    sx={{
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: { xs: 'stretch', sm: 'center' },
                        justifyContent: { xs: 'center', sm: 'flex-start' },
                    }}
                >
                    <Button
                        component={Link}
                        to="/start-allvit-pluss"
                        variant="primary"
                        overrideColor={theme.palette.text.primary}
                        sx={{
                            mr: { xs: 0, sm: 6 },
                            mb: { xs: 4, sm: 0 },
                            width: { xs: '100%', sm: 'auto' },
                            justifyContent: { xs: 'center', sm: 'flex-start' },
                        }}
                        size="large"
                    >
                        Start Allvit+ nå!
                    </Button>
                    <Button
                        component={Link}
                        to="/allvit-pluss"
                        overrideColor={theme.palette.text.primary}
                        variant="secondary"
                        size="large"
                        sx={{
                            width: { xs: '100%', sm: 'auto' },
                            justifyContent: { xs: 'center', sm: 'flex-start' },
                        }}
                    >
                        Les mer om Allvit+
                    </Button>
                </Stack>
            </Box>
        );
    }

    if (variant === AllvitPlussBannerVariant.BookDetails) {
        return (
            <Stack
                sx={{
                    mb: { xs: 8, md: 12 },
                    p: 6,
                    flexDirection: 'row',
                    justifyContent: { xs: 'center', sm: 'space-between' },
                    backgroundColor:
                        theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.dark,
                    borderRadius: theme.radius.allLarge,
                    backgroundImage: { xs: 'none', sm: `url(${imageUrls.extractedSmartStudent})` },
                    backgroundPosition: 'bottom right',
                    backgroundSize: { sm: '240px auto', md: '290px auto', lg: '310px auto' },
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Stack sx={{ alignItems: { xs: 'center', sm: 'flex-start' } }}>
                    <Heading
                        variant="h5"
                        sx={{
                            mb: 4,
                            mr: { xs: '0', md: '260px' },
                            textAlign: { xs: 'center', sm: 'initial' },
                        }}
                    >
                        Denne boka er inkludert i abonnementet Allvit+
                    </Heading>
                    <Typography
                        variant="p2"
                        sx={{
                            mb: 6,
                            mr: { xs: '0', sm: '260px' },
                            color: theme.palette.primary.contrastText,
                            textAlign: { xs: 'center', sm: 'initial' },
                        }}
                        data-cy="cy-book-status-offshelf-subscription"
                    >
                        Visste du at du kan abonnere på pensumbøker og få tilgang til denne og omtrent 2000 andre bøker?
                    </Typography>

                    <Button
                        variant="primary"
                        size="large"
                        overrideColor={theme.palette.text.primary}
                        component={Link}
                        to="/allvit-pluss"
                        sx={{ width: 'fit-content' }}
                    >
                        Les mer om Allvit+
                    </Button>
                </Stack>
            </Stack>
        );
    }

    if (variant === AllvitPlussBannerVariant.Cart && Array.isArray(cartItemsAvailableInSubscription)) {
        return (
            <Stack
                sx={{
                    mb: 12,
                    p: 6,

                    flexDirection: { md: 'column', lg: 'row' },
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor:
                        theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.dark,
                    borderRadius: theme.radius.allLarge,
                }}
            >
                <Typography
                    variant="p2"
                    sx={{
                        maxWidth: '600px',
                        pr: { xs: 0, sm: 6 },
                        mb: { xs: 4, sm: 0 },
                        color: theme.palette.primary.contrastText,
                        textAlign: { xs: 'center', sm: 'left' },
                    }}
                >
                    <b>
                        {cartItemsAvailableInSubscription.length}{' '}
                        {cartItemsAvailableInSubscription.length > 1 ? 'bøker' : 'bok'}
                    </b>{' '}
                    i handlekurven din er inkludert i{' '}
                    <Button
                        component={Link}
                        to="/allvit-pluss"
                        overrideColor={theme.palette.text.primary}
                        variant="link"
                    >
                        Allvit+
                    </Button>
                    . Visste du at du kan abonnere på pensumbøker og få tilgang til{' '}
                    {cartItemsAvailableInSubscription.length > 1 ? 'disse' : 'denne'} og omtrent 2000 andre bøker?
                </Typography>
                <Button
                    variant="primary"
                    size="large"
                    overrideColor={theme.palette.text.primary}
                    component={Link}
                    to="/allvit-pluss"
                    sx={{
                        flexShrink: '0',
                        mt: { sm: 4, md: 4, lg: 0 },
                    }}
                >
                    Les mer om Allvit+
                </Button>
            </Stack>
        );
    }

    if (variant === AllvitPlussBannerVariant.Dashboard && typeof userIsSubscriber !== 'undefined') {
        return <AllvitPlussBannerDashboard userIsSubscriber={userIsSubscriber} setShowSubModal={setShowSubModal} />;
    }

    return <></>;
};
